<template>
  <div v-loading="loading">
    <h2>{{ article.title }}</h2>
    <Markdown :source="article.contentMd" align="left"
                       preview-class="vuepress-markdown-body"></Markdown>

    <el-divider/>

    <div>
      <h3 align="left">发表留言</h3>

      <el-row>
        <el-col :span="24">
          <el-form ref="formRules" :model="commentForm" :rules="formRules" align="left" label-width="120px">

            <el-form-item label="留言" prop="comment">
              <el-input v-model="commentForm.comment" type="textarea"/>
            </el-form-item>

            <el-form-item label="图片验证码" prop="kaptcha">
              <el-input v-model="commentForm.kaptcha"/>
              <el-image :src="kaptchaImg" @click="getKaptcha"/>
            </el-form-item>
            <el-form-item>
              <el-button @click="comment">
                <el-icon>
                  <Comment/>
                  <el-icon>
                    <Comment/>
                  </el-icon>
                </el-icon>
                留言
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-divider/>

    <div style="child-align: left">
      <h3 align="left">全部评论</h3>


      <ArticleComment :list="commentList"></ArticleComment>


    </div>
  </div>
</template>

<script>
import {comment, detail, kaptcha} from "@/utils/article";
import {computed, reactive} from 'vue'
import Markdown from 'vue3-markdown-it';
import {useSeoMeta} from '@vueuse/head'
import ArticleComment from '@/components/ArticleComent.vue'


const siteData = reactive({})

export default {
  name: "ArticleL",
  setup() {
    useSeoMeta({
      // Can be static or computed
      title: computed(() => siteData.title),
      description: computed(() => siteData.description),
      keywords: computed(() => siteData.keywords)
    })
  },
  data() {
    return {
      loading: true,
      commentList: [],
      kaptchaImg: undefined,
      commentForm: {
        comment: undefined,
        articleId: undefined,
        kaptcha: undefined,
        kaptchaId: undefined,
      },
      formRules: {
        comment: [
          {required: true, message: '留言内容不能为空', trigger: ['blur', 'change']}
        ],
        kaptcha: [
          {required: true, message: '图片验证码不能为空', trigger: ['blur', 'change']},
          {min: 4, max: 4, message: "图片验证码不正确", trigger: ['blur', 'change']}
        ]
      },
      article: {
        contentMd: ""
      }
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.search()
    this.getKaptcha()


  },
  components: {
    ArticleComment, Markdown
  },
  methods: {
    getKaptcha() {
      kaptcha().then(response => {

        this.commentForm.kaptcha = undefined
        this.commentForm.kaptchaId = response.kaptchaId
        this.kaptchaImg = response.kaptcha


      }).catch(() => {

      })
    },
    search() {
      this.$gtag.event('detail', { method: 'detail' })

      detail({id: this.id}).then(response => {
        this.article = response
        this.commentForm.articleId = response.id
        this.commentList = response.comments
        this.loading = false

        siteData.title = response.title
        siteData.description = response.description
        siteData.keywords = response.keywords + "," + response.typeName

      }).catch(() => {

      })
    },
    comment() {
      this.$refs['formRules'].validate((valid) => {
        if (!valid) {
          return false
        }
      })
      this.$gtag.event('comment', { method: 'comment' })
      comment(this.commentForm).then(response => {
        this.commentForm.comment = undefined
        console.log(response)
        this.search();


      }).catch(() => {

      }).finally(() => {
        this.getKaptcha()
      })

    }
  }
}
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
