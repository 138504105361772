import {createApp} from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import router from './router/index' // 加载 router 底下的 index.js 路由配置文件
import {createHead} from "@vueuse/head"
import "./icons";
import Markdown from 'vue3-markdown-it';
import 'highlight.js/styles/monokai.css';
import VueGtag from 'vue-gtag';

const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.use(createHead())
app.use(Markdown);


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(VueGtag, {
    config: {
        id: 'G-GJWDHN3DQ3',
    },
}, router).mount('#app')
