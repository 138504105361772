import axios from 'axios'
import {ElMessage} from "element-plus";


const request = axios.create({
    //baseURL: "https://shuowang.xyz/java/",
    baseURL: "https://cui.cool/blog",
    timeout: 100000
})


// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';

    let user = JSON.parse(localStorage.getItem('user'))
    if (user != null) {
        config.headers['token'] = user.token;
    }

    config.headers['activityId'] = localStorage.getItem("activityId")
    config.headers['channel'] = localStorage.getItem("channel")

    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        if (res.errno === 0) {
            return res.data


        } else if (res.errno !== 0) {
            // 非5xx的错误属于业务错误，留给具体页面处理

            ElMessage({
                message: res.errmsg,
                type: 'error',
                duration: 5 * 1000
            })
            if (res.errno === 501) {
                location.href = '/login'

            }


            return Promise.reject(response)
        }

    }
    ,
    error => {
        console.log('err' + error) // for debug
        ElMessage({
            message: '系统开小差了',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)


export default request
