<template>
  <el-card v-for="item in list" :key="item" :index="item.id" align="left">

    <template #header>
      <div class="card-header">
        <div>
          <SvgIcon icon-class="ip"></SvgIcon>
          {{ item.name }}
        </div>
        <div>
          <SvgIcon icon-class="time"></SvgIcon>
          {{ item.createTime }}
        </div>
      </div>
    </template>
    <p>
      {{ item.comment }}
    </p>
    <el-card v-for="childItem in item.child" :key="childItem" :index="childItem.id" align="left">

      <template #header>
        <div class="card-header">
          <div>
            <SvgIcon icon-class="ip"></SvgIcon>
            {{ childItem.name }}
          </div>
          <div>
            <SvgIcon icon-class="time"></SvgIcon>
            {{ childItem.createTime }}
          </div>
        </div>
      </template>
      <p>
        {{ childItem.comment }}
      </p>
    </el-card>
  </el-card>

</template>

<script>
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "ArticleComment",
  props: ['list'],
  components: {
    SvgIcon
  },
}
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
