import {createApp} from 'vue'
import SvgIcon from "@/components/SvgIcon";
import App from "@/App";
const app = createApp(App)
// register globally
app.component("svg-icon", SvgIcon);

const req = require.context("./svg", false, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(req);
