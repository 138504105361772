<template>


  <div v-for="item in articleList" :key="item" :index="item.id" align="left">

    <el-link @click="article(item.id)">
      <h2>
        <el-button size="small" type="info">{{ item.typeName }}</el-button>
        {{ item.title }}
      </h2>

    </el-link>
    <p style="font-size: large">
      {{ item.description }}
    </p>


    <el-space wrap>

      <el-statistic :value="item.createTime">
        <template #title>
          <div style="display: inline-flex; align-items: center">
            时间
            <SvgIcon icon-class="time"></SvgIcon>
          </div>
        </template>
      </el-statistic>

      <el-statistic :value="item.viewCount">
        <template #title>
          <div style="display: inline-flex; align-items: center">
            浏览次数
            <el-icon :size="12" style="margin-left: 4px">
              <View/>
            </el-icon>
          </div>
        </template>
      </el-statistic>
      <el-statistic :value="item.commentCount" title="评论次数">
        <template #title>
          <div style="display: inline-flex; align-items: center">
            评论次数
            <el-icon :size="12" style="margin-left: 4px">
              <ChatDotRound/>
            </el-icon>
          </div>
        </template>
      </el-statistic>
    </el-space>
  </div>
  <div style="margin-top: 10px;">
    <el-pagination v-model:current-page="form.page" :small="false" :total="total" layout="prev, pager, next"
                   @current-change="search()"/>
  </div>


</template>

<script>
import {article} from "@/utils/article";
import SvgIcon from "@/components/SvgIcon";

export default {
  name: "HomeL",
  data() {
    return {
      total: undefined,
      form: {
        typeId: undefined,
        page: 1
      },
      articleList: undefined,
    }
  },
  components: {SvgIcon},
  created() {
    this.form.typeId = this.$route.params.typeId
    this.search()
  },
  watch: {
    $route() {
      this.$router.go(0);
    }
  },
  methods: {
    search() {
      this.$gtag.event('home', { method: 'home' })

      article(this.form).then(response => {
        this.articleList = response.list;
        this.total = response.total
        this.form.page = response.page

      }).catch(() => {

      })
    },
    article(id) {
      this.$gtag.event('article', { method: 'article' })

      this.$router.push({path: '/article/' + id});

    }

  }
}
</script>

<style scoped>

</style>
