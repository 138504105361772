<template>

  <div>
    <el-container style="min-height: 1000px">
      <el-header>
        <Head></Head>
      </el-header>
      <el-container>
        <el-aside width="5%"></el-aside>

        <el-main>
          <router-view/>
        </el-main>

        <el-aside width="5%"></el-aside>


      </el-container>
      <el-divider></el-divider>
      <el-footer>
        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备20030728号-1</a>
      </el-footer>
    </el-container>
    <el-backtop bottom="100" right="100"/>
  </div>


</template>

<script>
import {useSeoMeta} from '@vueuse/head'
import Head from '@/components/Head.vue'

export default {
  name: 'App',
  setup() {
    useSeoMeta({
      // Can be static or computed
      title: "技术学习日志",
      description: `诗与远方`,
      generator: 'vue3',
      distribution: "global",
      keywords: "java,vue3,linux,博客",
      charset: "UTF-8",
      viewport: "width=device-width, initial-scale=1.0,maximum-scale=1.0, user-scalable=no"
    })
  },
  components: {Head},
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
