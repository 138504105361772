import Home from '@/views/Home'
import Test from '@/views/Test'

import Article from '@/views/Article'

const routes = [
    {
        name: 'type',
        path: '/type/:typeId',
        component: Home,
        meta: {}
    },
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {}
    },
    {
        name: 'test',
        path: '/test',
        component: Test,
        meta: {}
    },
    {
        name: 'article',
        path: '/article/:id',
        component: Article,
        meta: {}
    }

];
export default routes
