<template>

  <el-menu mode="horizontal">

    <el-menu-item index="0">
      <router-link style="text-decoration: none;" to='/'>
        <el-icon>
          <HomeFilled></HomeFilled>
        </el-icon>
        技术学习日志
      </router-link>
    </el-menu-item>


    <div class="flex-grow"></div>

    <el-menu-item v-for="item in types" :key="item" :index="item.typeName" @click="clickMenu(item.id)">
      <router-link style="text-decoration: none;" to='/'>
        <SvgIcon :icon-class="item.icon"/>
        {{ item.name }}
      </router-link>

    </el-menu-item>

  </el-menu>

</template>

<script>
import {article, types} from "@/utils/article";
import SvgIcon from '@/components/SvgIcon.vue'

export default {
  name: 'HeadL',
  components: {SvgIcon},
  data() {
    return {
      types: []
    }
  },
  created() {

    types().then(response => {
      this.types = response;
    }).catch(() => {

    })


  },

  methods: {
    search(param) {
      article(param).then(response => {
        this.articleList = response.list;
      }).catch(() => {

      })
    },
    clickMenu(typeId) {
      this.$router.push({path: `/type/${typeId}`});
    }

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.flex-grow {
  flex-grow: 0.9;
}

.my-autocomplete li {
  line-height: normal;
  padding: 20px;
}


</style>
