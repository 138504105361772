import request from "@/utils/request";

export function types() {
    return request({
        url: '/type',
        method: 'get'
    })
}

export function article(data) {
    return request({
        url: '/article',
        method: 'get',
        params: data
    })
}

export function detail(data) {
    return request({
        url: '/detail',
        method: 'get',
        params: data
    })
}

export function kaptcha(data) {
    return request({
        url: '/kaptcha',
        method: 'get',
        params: data
    })
}

export function comment(data) {
    return request({
        url: '/comment',
        method: 'post',
        data: data
    })
}

