<template>

  <el-row :gutter="20">
    <el-col :offset="6" :span="6">
      <el-form label-position="top" size="large">
        <el-form-item label="姓名">
          <el-input label="text"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input type="text"></el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>

</template>


<script>
export default {
  name: "TestL"
}
</script>

<style scoped>
.el-form-item {
  min-width: 200px;
}
</style>
